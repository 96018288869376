import { Card, ListGroup } from "react-bootstrap";
import styles from "./Chirurgie.module.css";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";


export function Chirurgie(){


return(

    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Chirurgie | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/servicii/chirurgie" />
       <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, stomatolog bran, dentist brasov, stomatolog brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Chirurgie | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta property="og:url" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:title" content="Chirurgie | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
<div className={styles["sectiune"]}>
 <Card style={{ width: '100rem' }} className={styles["card"]}>
    <div className={styles["images"]}>
      <Card.Img variant="top" src="https://i.imgur.com/NmnqFUq.png" className={styles["img"]}/>
      <Card.Img variant="top" src="https://i.imgur.com/j3dgfc8.png" className={styles["img"]}/>
      <Card.Img variant="top" src="https://i.imgur.com/WDr06hI.png" className={styles["img"]}/>
    
      </div>
      <Card.Body>
        <h4> <strong>Chirurgia</strong></h4>
        <Card.Text className={styles["text"]}>
       <p> Chirurgia dentară este o ramură a medicinei dentare care se ocupă cu diagnosticul, tratamentul și gestionarea intervențiilor chirurgicale ale problemelor dentare și maxilofaciale. Aceasta poate implica o gamă largă de proceduri, de la extracții dentare simple până la intervenții chirurgicale complexe precum implanturi dentare sau corecții ale structurii osoase maxilare.</p>
        <p>Iată câteva dintre procedurile comune în chirurgia dentară:</p>
<ul>
<li><strong>Extracții dentare:</strong> Îndepărtarea dinților afectați de carii avansate, fracturi sau alte afecțiuni care nu pot fi salvate prin alte tratamente.</li>

<li><strong>Chirurgie parodontală:</strong> Tratamentele pentru afecțiunile parodontale, cum ar fi gingivita sau parodontita, care implică tratamentul țesuturilor gingivale și a oaselor maxilare.</li>

<li><strong>Chirurgie orală: </strong>Include proceduri precum îndepărtarea chisturilor, tratarea leziunilor bucale și a infecțiilor, corecția unor afecțiuni congenitale sau traumatice ale cavității bucale și a maxilarelor.</li>

<li><strong>Implantologie dentară:</strong> Inserarea de implanturi dentare pentru înlocuirea dinților lipsă, care implică plasarea unor structuri metalice sau ceramice în osul maxilar pentru a susține coroanele dentare.</li>

<li><strong>Chirurgie ortodontică:</strong> Chirurgia asociată cu corectarea problemelor ortodontice, cum ar fi alinierea incorectă a maxilarelor sau corectarea disfuncțiilor articulației temporomandibulare.</li>

<li><strong>Chirurgie reconstructivă:</strong> Intervenții chirurgicale pentru corectarea defectelor faciale sau a rănilor cauzate de traume sau boli.</li>

<li>Este important să consulți un medic dentist sau un chirurg dentar pentru a evalua opțiunile de tratament și pentru a determina cel mai potrivit plan pentru nevoile tale dentare specifice.</li>
</ul>
        </Card.Text>
      </Card.Body>
      
      <Card.Body className={styles["cardBody"]}>
    
      <FontAwesomeIcon icon={faBackward} />  <Link to="/servicii/parodontologia" className={styles["link"]} onClick={() => {
    window.scroll(0, 0);
  }} >&nbsp;Parodontologia</Link>
      <Link to="/servicii/pedodontie" className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} > Pedodontie&nbsp;<FontAwesomeIcon icon={faForward} /></Link>
      </Card.Body>
    </Card>
    </div>
    </>
    )
}