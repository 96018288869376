import styles from "./Contact.module.css";
import { Button } from "react-bootstrap";
import { Helmet } from 'react-helmet'
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faWhatsapp,faWaze } from "@fortawesome/free-brands-svg-icons";

export function Contact() {
  const recaptcha = useRef()
  const [capVal, setCapVal] = useState(null);
    const [index, setIndex] = useState(0);
    const form = useRef();
    // const alert_message = `Programarea ta a fost trimisa!`;
    const [basicModal, setBasicModal] = useState(false);
  
    const toggleOpen = () => setBasicModal(!basicModal);
    const sendEmail = (e) => {
      e.preventDefault();
   
  
    
      emailjs
        .sendForm(
          "service_o7ue6dj",
          "template_aolzofr",
          form.current,
          "KYxyvtusiDQvtKEnw"
        )
        .then(
          (result) => {
            setBasicModal(!basicModal);
          },
          (error) => {
            console.log(error.text);
          }
        );
    };
    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
    async function submitForm(event) {
      event.preventDefault()
      const captchaValue = recaptcha.current.getValue()
      if (!captchaValue) {
        alert('Please verify the reCAPTCHA!')
      } else {
        // make form submission
        sendEmail(event);
      }
    }

  return (
    
    <>
  <Helmet>
                <meta charSet="utf-8" />
                <title>Contact | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/contact" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, dentist simon, dentist moieciu de sus, dentist brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Contact | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content="Contact | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>

    <h1><strong>Contact</strong></h1>
    <div className={styles["contact1"]}>
      <img
        src="https://i.imgur.com/5k83YEa.png"
        alt="contact"
        className={styles["figure"]}
      />
      <div className={styles["adresa"]}>
      <div>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp; Adresa: Moieciu de Jos, Brașov
            </div>
            <div>
              <FontAwesomeIcon icon={faPhone} />
              &nbsp; tel: <a href="tel:0752859298">0752 859 298</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} /> &nbsp;email:{" "}
              <a href="mailto:dr.cosmin.musuroi@gmail.com">
                dr.cosmin.musuroi@gmail.com
              </a>
            
              <div className={styles["socialMediaButtons"]}>
              <a href="https://www.facebook.com/profile.php?id=61559571415026">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://www.instagram.com/carpathiandentist/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="" >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href="https://ul.waze.com/ul?place=ChIJ_SBwZfg3s0ARRwX8ZN89Isg&ll=45.49574790%2C25.33060720&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
              <FontAwesomeIcon icon={faWaze} />
              </a>
              </div>
            </div>
      </div>
</div>

<h4>Solicită o programare folosind formularul:</h4>
      <div className={styles["contact"]}>
        {}
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        <form ref={form} onSubmit={sendEmail} className={styles["form"]} >
          <label>Nume și prenume *</label>
          <input
            required
            type="text"
            name="user_name"
            id="user_name"
            className={styles["input"]}
          />
          <label>Email *</label>
          <input
            required
            type="email"
            name="user_email"
            placeholder="nume@example.com"
            className={styles["input"]}
          />
          <label>Număr telefon *</label>
          <input
            
            type="phone"
            name="user_phone"
            placeholder="0745 000 000"
            className={styles["input"]}
          />
          <label>Motivul programării:</label>
          <textarea
            name="message"
            as="textarea"
            rows={3}
            className={styles["message"]}
          />
          {/* <input type="submit" value="Send" className="mb-3" /> */}
          <div class="g-recaptcha" data-sitekey="6LfnqdYpAAAAABD50Vzwf1Rj8z2Eeu9l2_bjrJDT"></div>
          <div className={styles["acord"]}>
          <input type="checkbox"name="checkbox" value="checkbox" className={styles["acord2"]} required/>
          <label for="checkbox"> Sunt de acord cu colectarea datelor personale furnizate în formularul de mai sus, pentru efectuarea programării. Pentru mai multe informații referitoare la prelucrarea datelor cu caracter personal, vă rugăm să consultați <a href="/politica-confidentialitate">politica GDPR.</a>
          </label>
      
          </div>
          <ReCAPTCHA sitekey="6LfnqdYpAAAAABD50Vzwf1Rj8z2Eeu9l2_bjrJDT" 
          ref={recaptcha}
          className={styles["captcha"]}
          />
    
        
         <Button  

            className={styles["submit2"]}
          >
       
          </Button>
          <input type="submit"   variant="outline-success"
            value="Trimite"  className={styles["submit"]}/>
        </form>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d174.79425982993772!2d25.33032978748338!3d45.49582139227349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b337f8657020fd%3A0xc8223ddf64fc0547!2sCarpathian%20Dentist!5e0!3m2!1sro!2sro!4v1714383316629!5m2!1sro!2sro"
            width="600"
            height="500"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <br></br>

      <MDBModal open={basicModal} setopen={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Info</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Programarea ta a fost înregistrată, vei primi confirmarea pe
              email.{" "}
            </MDBModalBody>

            <MDBModalFooter>
              <a href="/home">
                {" "}
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Închide
                </MDBBtn>
              </a>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
   
    </>
  );
}
