import { Card, ListGroup } from "react-bootstrap";
import styles from "./Parodontologia.module.css";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export function Parodontologia(){



    return(
    <>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Parodontologia | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/servicii/parodontologia" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist brasov, dentist bran, stomatolog bran, stomatolog brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Parodontologia | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta property="og:url" content="https://carpathiandentist/parodontologie.ro" />
        <meta name="facebook:title" content="Parodontologia | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
    
    
    
    <div className={styles["sectiune"]}>
<Card style={{ width: '100rem' }} className={styles["card"]}>
   <div className={styles["images"]}>
     <Card.Img variant="top" src="https://i.imgur.com/jHrrUbk.png" className={styles["img"]}/>
     <Card.Img variant="top" src="https://i.imgur.com/rXTzA9S.png" className={styles["img"]}/>
     <Card.Img variant="top" src="https://i.imgur.com/1WsMPLe.png" className={styles["img"]}/>
     </div>
     <Card.Body>
       <h4> <strong>Parodontologia</strong></h4>
       <Card.Text className={styles["text"]}>
      <p> 

      Parodontologia este o ramură a medicinei dentare care se concentrează pe diagnosticul, prevenirea și tratamentul bolilor parodontale, cunoscute și sub denumirea de bolile gingiei sau ale țesuturilor de susținere a dinților. Aceste afecțiuni afectează gingiile și țesuturile de susținere a dinților, cum ar fi osul și ligamentele care țin dinții în poziție.</p>
       <p>Iată câteva aspecte importante ale parodontologiei:</p>
<ul>
<li><strong>Gingivita: </strong>Este o inflamație a gingiilor care este cauzată de acumularea de placa bacteriană la marginea gingiilor. Simptomele comune ale gingivitei includ sângerarea gingiilor la periaj sau la utilizarea aței dentare, umflarea și roșeața gingiilor.</li>

<li><strong>Parodontita: </strong>Este o afecțiune mai avansată decât gingivita, caracterizată prin inflamație și deteriorarea țesuturilor de susținere a dinților, inclusiv a osului și a ligamentelor. Parodontita poate duce la retragerea gingiilor, formarea de buzunare parodontale (spații adânci între gingii și dinți), mobilitatea și chiar pierderea dinților.</li>

<li><strong>Tratamentul parodontal: </strong>Include proceduri precum detartrajul și detartrajul subgingival pentru îndepărtarea plăcii bacteriene și a tartrului de pe suprafața dinților și din buzunarele parodontale. În cazuri mai severe, pot fi necesare proceduri chirurgicale parodontale pentru a trata leziunile mai avansate ale țesuturilor de susținere a dinților.</li>

<li><strong>Mentinerea sănătății parodontale:</strong> În plus față de tratamentul activ al bolilor parodontale, este importantă igiena orală regulată și vizitele regulate la dentist pentru igienizare profesională și evaluare a stării parodontale.</li>
<li><strong>Chirurgia parodontală estetică:</strong> Uneori, chirurgia parodontală poate fi folosită pentru a îmbunătăți aspectul estetic al zonelor afectate de retracția gingiilor sau de alte afecțiuni parodontale.</li>

</ul>
<p>Este important să consultați un medic dentist sau un specialist în Parodontologia dentară pentru a determina dacă sunteți un candidat potrivit pentru implanturi dentare și pentru a dezvolta un plan de tratament personalizat în funcție de nevoile și obiectivele dumneavoastră dentare.</p>

       </Card.Text>
     </Card.Body>
     
     <Card.Body className={styles["cardBody"]}>
   
     <FontAwesomeIcon icon={faBackward} /><Link to="/servicii/implantologie"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >&nbsp;Implantologia</Link>
     <Link to="/servicii/chirurgie" className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} > Chirurgie&nbsp;<FontAwesomeIcon icon={faForward} /></Link>
     </Card.Body>
   </Card>
   </div></>)}