import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faWaze
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBContainer, MDBFooter } from "mdb-react-ui-kit";

import styles from "./Footer.module.css";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export function Footer() {
  return (
    <>
    <Helmet>
    <meta charSet="utf-8" />
    <title> Dentist Moieciu de Jos | Carpathian Dentist | Cabinet dentar </title>
    <link rel="canonical" href="/contact" />
    <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
<meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, dentist moieciu de sus, dentist brasov" />
<meta name="author" content="Carpathian Dentist" />
<meta property="og:title" content="Tratamente decontate prin CAS. Dentist Moieciu de Jos| Carpathian Dentist | Cabinet dentar" />
<meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
<meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
<meta property="og:url" content="https://carpathiandentist.ro" />
<meta name="facebook:title" content="Contact | Carpathian Dentist | Cabinet dentar" />
<meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
<meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
<meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





</Helmet>
    <footer>
      <MDBFooter
        className="text-center text-white"
        style={{ backgroundColor: "#237e60" }}
      >
        <MDBContainer className="p-4 pb-4">
          <section className="">
        
            <p className={styles["programeazate"]}>
            
                Programează-te pentru o consultație! 
            
            </p>
       
            <div>
            
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp; Moieciu de Jos, Brașov
            </div>
            <div>
            <a href="https://ul.waze.com/ul?place=ChIJ_SBwZfg3s0ARRwX8ZN89Isg&ll=45.49574790%2C25.33060720&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
              <FontAwesomeIcon icon={faWaze} /> &nbsp; Vezi pe Waze
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;  <a href="tel:0752859298">0752 859 298</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
              <a href="mailto:dr.cosmin.musuroi@gmail.com">
                dr.cosmin.musuroi@gmail.com
              </a>
            </div>
            <div className={styles["socialButtons"]}>
            <Link to="https://www.facebook.com/profile.php?id=61559571415026"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link to="https://www.instagram.com/carpathiandentist/"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >
                <FontAwesomeIcon icon={faInstagram} />
              </Link>
              <a href="">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <div>
            <a href="https://ul.waze.com/ul?place=ChIJ_SBwZfg3s0ARRwX8ZN89Isg&ll=45.49574790%2C25.33060720&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
              <FontAwesomeIcon icon={faWaze} />
              </a>
            </div>
            </div>
            <Link to="/politica-confidentialitate"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >Politică confidențialitate</Link><br></br>
   <Link to="/tratamente-cas"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >Tratamente decontate prin CAS</Link><br></br>
   <Link to="/despre-noi"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >Despre noi</Link>
          </section>
        </MDBContainer>

        <div
          className="text-center p-3 text-white"
          style={{ backgroundColor: "#000000" }}
        >
          © 2024 Copyright:
          <a className="text-white" href="carpathiandentist.ro"  >
            Carpathian Dentist
          </a>
          <div className="text-center p-3 ">
            {" "}
            Made by{" "}
            <a href="https://www.facebook.com/andree.andreea22">
         Baltatu Andreea
            </a>
          </div>
        </div>
      </MDBFooter>
    </footer>
    </>
  );
}
