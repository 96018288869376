import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Nav.module.css";
import { Button, NavDropdown } from "react-bootstrap";
import { Link, Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
export function Navigation() {
  const [currentURL, setCurrentURL] = useState('');

    const showCurrentURL = () => {
        setCurrentURL(window.location.href);
    };

  
  return (
    <>
    <Helmet>
    <meta charSet="utf-8" />
    <title> Dentist Moieciu de Jos | Carpathian Dentist | Cabinet dentar </title>
    <link rel="canonical" href="/contact" />
    <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
<meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, dentist moieciu de sus, dentist brasov" />
<meta name="author" content="Carpathian Dentist" />
<meta property="og:title" content="Tratamente decontate prin CAS. Dentist Moieciu de Jos| Carpathian Dentist | Cabinet dentar | Cabinet Bran | Cabinet Brasov" />
<meta property="og:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
<meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
<meta property="og:url" content="https://carpathiandentist.ro" />
<meta name="facebook:title" content="Contact | Carpathian Dentist | Cabinet dentar" />
<meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
<meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
<meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





</Helmet>
    <nav id="headerNav">
   
      <ul className={styles["navbarNavDropdown"]}>
        
        <li class="nav-item">
          <Link to="/" className={styles["linkuri"]}>
            Home
          </Link>
        </li>
        <li class="nav-item">
          <NavDropdown
            id="nav-dropdown-dark-example"
            title="Servicii"
            menuVariant="white"
          >
            <NavDropdown.Item>
              <Link to="/servicii" className={styles["linkuri"]}>
                {" "}
                Toate serviciile
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/servicii/pedodontie" className={styles["linkuri"]}>
                Pedodonție
              </Link>
            </NavDropdown.Item>

            <NavDropdown.Item>
              <Link to="/servicii/endodontie" className={styles["linkuri"]}>
                Endodonție
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/servicii/protetica" className={styles["linkuri"]}>
                Protetică
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/servicii/implantologie" className={styles["linkuri"]}>
                Implantologie
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/servicii/parodontologia" className={styles["linkuri"]}>
                Parodontologie
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/servicii/chirurgie" className={styles["linkuri"]}>
                Chirurgie
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/tratamente-cas" className={styles["linkuri"]}>
                {" "}
                Tratamente decontate prin CAS
              </Link>
            </NavDropdown.Item>
          </NavDropdown>
        </li>
        <Link to="/" className={styles["linkuri"]}>
          <img src="https://i.imgur.com/VZKZISb.png" height="80" alt="logo" />
        </Link>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link mx-2" href="/">
            {/* <img src="https://i.imgur.com/YdJG3Rd.png" height="90" alt="logo" /> */}
          </a>
        </li>
        <li class="nav-item">
          <Link to="/preturi" className={styles["linkuri"]}>
            Prețuri
          </Link>
        </li>
        <li class="nav-item">
        <NavDropdown
            id="nav-dropdown-dark-example"
            title="Contact"
            menuVariant="white"
          >
            <NavDropdown.Item>
              <Link to="/contact" className={styles["linkuri"]}>
                {" "}
               Contact
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/despre-noi" className={styles["linkuri"]}>
                Despre noi
              </Link>
            </NavDropdown.Item>
            </NavDropdown>

        </li>
    
      </ul>
      
    </nav>
    
    </>
  );
  

}
