import { Card } from "react-bootstrap";
import styles from "./CAS.module.css";
import { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from 'react-helmet'
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from "mdb-react-ui-kit";

export function CAS() {
    const recaptcha = useRef()
    const [capVal, setCapVal] = useState(null);
      const [index, setIndex] = useState(0);
      const form = useRef();
      // const alert_message = `Programarea ta a fost trimisa!`;
      const [basicModal, setBasicModal] = useState(false);
    
      const toggleOpen = () => setBasicModal(!basicModal);
      const sendEmail = (e) => {
        e.preventDefault();
     
    
      
        emailjs
          .sendForm(
            "service_o7ue6dj",
            "template_aolzofr",
            form.current,
            "KYxyvtusiDQvtKEnw"
          )
          .then(
            (result) => {
              setBasicModal(!basicModal);
            },
            (error) => {
              console.log(error.text);
            }
          );
      };
      const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
      };
      async function submitForm(event) {
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
          alert('Please verify the reCAPTCHA!')
        } else {
          // make form submission
          sendEmail(event);
        }
      }

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title> Tratamente decontate prin CAS </title>
                <link rel="canonical" href="/*" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="dentist,Tratamente decontate prin CAS, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara" />
        <meta name="author" content="Tratamente decontate prin CAS." />
        <meta property="og:title" content=" Tratamente decontate prin CAS | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content=" Tratamente decontate prin CAS | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="summary_large_image" />





            </Helmet>
      <main className={styles["container"]}>
      <h1><strong>Tratamente decontacte CAS</strong></h1>
        <img src="https://i.imgur.com/aRuQcTk.png" alt="404" className={styles["CASlogo"]}/>

       <p>🦷Wow! Vesti minunate! Tratamente Stomatologice <strong>decontate de CAS</strong>, acum la <strong>Carpathian Dentist</strong></p>

<p>Pentru noi, sănătatea zâmbetului tău este o prioritate! Oferim tratamente stomatologice decontate prin Casa de Asigurări de Sănătate (CAS), astfel încât să îți poți menține sănătatea orală fără grija costurilor! 🏥💙</p>
<div>
<p className={styles["titluri"]}>✅ <strong>Ce tipuri de tratamente poti accesa?</strong></p>
<p>🔹 Consultații și controale de rutină</p>
<p>🔹 Tratamente Stomatologice</p>
<p>🔹 Intervenții și tratamente de urgență</p>
</div>
<div>
<p>💡 <strong>Cum beneficiezi de tratamente gratuite sau decontate?</strong></p>
<p>1️⃣Programează-te pentru o consultație la noi</p>
<p>2️⃣Prezintă cardul de asigurat</p>
<p>3️⃣Bucură-te de tratamente stomatologice la costuri minime!</p>
</div>
<div>
<strong>📞 Sună acum</strong> la <a href="tel:0752859298">0752 859 298</a> pentru programări sau programează-te direct folosind formularul de mai jos:
</div>
<div className={styles["contact"]}>
 <form ref={form} onSubmit={sendEmail} className={styles["form"]} >
          <label>Nume și prenume *</label>
          <input
            required
            type="text"
            name="user_name"
            id="user_name"
            className={styles["input"]}
          />
          <label>Email *</label>
          <input
            required
            type="email"
            name="user_email"
            placeholder="nume@example.com"
            className={styles["input"]}
          />
          <label>Număr telefon *</label>
          <input
            
            type="phone"
            name="user_phone"
            placeholder="0745 000 000"
            className={styles["input"]}
          />
          <label>Motivul programării:</label>
          <textarea
            name="message"
            as="textarea"
            rows={3}
            className={styles["message"]}
          />
          {/* <input type="submit" value="Send" className="mb-3" /> */}
          <div class="g-recaptcha" data-sitekey="6LfnqdYpAAAAABD50Vzwf1Rj8z2Eeu9l2_bjrJDT"></div>
          <div className={styles["acord"]}>
          <input type="checkbox"name="checkbox" value="checkbox" className={styles["acord2"]} required/>
          <label for="checkbox"> Sunt de acord cu colectarea datelor personale furnizate în formularul de mai sus, pentru efectuarea programării. Pentru mai multe informații referitoare la prelucrarea datelor cu caracter personal, vă rugăm să consultați <a href="/politica-confidentialitate">politica GDPR.</a>
          </label>
      
          </div>
          <ReCAPTCHA sitekey="6LfnqdYpAAAAABD50Vzwf1Rj8z2Eeu9l2_bjrJDT" 
          ref={recaptcha}
          className={styles["captcha"]}
          />
    
        
         <Button  

            className={styles["submit2"]}
          >
       
          </Button>
          <input type="submit"   variant="outline-success"
            value="Trimite"  className={styles["submit"]}/>
        </form>
        </div>
<p><b>Zâmbetul tău merită cea mai bună îngrijire!</b></p>

<div>
<strong>📌Unde ne găsești?</strong><br/>
<a href="https://www.google.com/maps/dir//Carpathian+Dentist+DN73F+355+Moieciu+de+Jos+507135/@45.4957479,25.3306073,20z/data=!4m5!4m4!1m0!1m2!1m1!1s0x40b337f8657020fd:0xc8223ddf64fc0547">DN73F 355, Moieciu de Jos 507135</a>
</div>
<div>
<p><strong>👩‍⚕️Noi suntem aici sa avem grija de tine! Hai sa iti aducem zambetul pe buze la costuri minime!</strong></p>
</div>

           
      </main>
      <MDBModal open={basicModal} setopen={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Info</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Programarea ta a fost înregistrată, vei primi confirmarea pe
              email.{" "}
            </MDBModalBody>

            <MDBModalFooter>
              <a href="/home">
                {" "}
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Închide
                </MDBBtn>
              </a>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}