import { Card, ListGroup } from "react-bootstrap";
import styles from "./Endodontie.module.css";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";



export function Endodontie(){

  
  
  
  
  return(<>
   <Helmet>
                <meta charSet="utf-8" />
                <title>Endodontie | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/servicii/endodontie" />
                <meta name="description" content="Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara, dentist bran, stomatolog bran, dentist brasov, stomatolog brasov" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Endodontie | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta property="og:url" content="https://carpathiandentist.ro/endodontie" />
        <meta name="facebook:title" content="Endodontie | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/Rcop7CM.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
<div className={styles["sectiune"]}>
 <Card style={{ width: '100rem' }} className={styles["card"]}>
    <div className={styles["images"]}>
      <Card.Img variant="top" src="https://i.imgur.com/FLxpn8P.png" className={styles["img"]}/>
      <Card.Img variant="top" src="https://i.imgur.com/mi2StCt.png" className={styles["img"]}/>
      <Card.Img variant="top" src="https://i.imgur.com/jR0Qqs5.png" className={styles["img"]}/>
      </div>
      <Card.Body>
        <h4> <strong>Endodontia</strong></h4>
        <Card.Text className={styles["text"]}>
       <p> 
Endodonția este o ramură a medicinei dentare care se ocupă cu diagnosticul și tratamentul afecțiunilor din interiorul dintelui, cum ar fi pulpita (inflamația pulpei dentare) și necroza pulpei (moartea pulpei). Scopul principal al endodontiei este de a salva dinții naturali, evitând extracțiile și înlocuirile cu proteze sau implanturi dentare. Aceasta poate implica o gamă largă de proceduri, de la extracții dentare simple până la intervenții chirurgicale complexe precum implanturi dentare sau corecții ale structurii osoase maxilare.</p>
        <p>Procedurile comune în endodonție includ:</p>
<ul>
<li><strong>Tratamentul de canal radicular:</strong> Aceasta este procedura prin care se îndepărtează țesutul moart sau inflamat din interiorul dintelui (pulpa) și se curăță și se sigilează canalul radicular pentru a preveni infecțiile ulterioare.</li>

<li><strong>Re-tratamentul de canal radicular:</strong> Dacă o infecție persistă sau reapare după un tratament de canal radicular inițial, poate fi necesar un re-tratament pentru a curăța și a sigila din nou canalul radicular.</li>

<li><strong>Chirurgia endodontică: </strong>În unele cazuri, cum ar fi când canalul radicular nu poate fi accesat sau tratat prin proceduri non-chirurgicale, poate fi necesară o intervenție chirurgicală pentru a trata infecția sau a salva dinte.</li>

<li><strong>Apicoectomia: </strong>Aceasta este o procedură chirurgicală în care se îndepărtează vârful rădăcinii dentare infectate și se curăță țesutul infectat din jurul rădăcinii pentru a preveni recurența infecției.</li>


</ul>
        </Card.Text>
      </Card.Body>
      
      <Card.Body className={styles["cardBody"]}>
    
      <FontAwesomeIcon icon={faBackward} /><Link to="/servicii/pedodontie"className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} >&nbsp;Pedodonție</Link>
      <Link to="/servicii/protetica" className={styles["link"]}onClick={() => {
    window.scroll(0, 0);
  }} > Protetică&nbsp;<FontAwesomeIcon icon={faForward} /></Link>
      </Card.Body>
    </Card>
    </div>
</>)}