
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./Home.module.css";
import { Button, Card } from "react-bootstrap";
// import { ReCAPTCHA } from "react-google-recaptcha"
import ReCAPTCHA from "react-google-recaptcha";
import { ElfsightWidget } from 'react-elfsight-widget';



import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
 
} from "mdb-react-ui-kit";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export function Home() {
  const recaptcha = useRef()
const [capVal, setCapVal] = useState(null);
  const [index, setIndex] = useState(0);
  const form = useRef();
  // const alert_message = `Programarea ta a fost trimisa!`;
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);
  const sendEmail = (e) => {
    e.preventDefault();
 

  
    emailjs
      .sendForm(
        "service_o7ue6dj",
        "template_aolzofr",
        form.current,
        "KYxyvtusiDQvtKEnw"
      )
      .then(
        (result) => {
          setBasicModal(!basicModal);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  async function submitForm(event) {
    event.preventDefault()
    const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      alert('Please verify the reCAPTCHA!')
    } else {
      // make form submission
      sendEmail(event);
    }
  }
  return (
    <>

  <Helmet>
                <meta charSet="utf-8" />
                <title> Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0757088840 si programeaza-te gratuit!" />
                <meta name="keywords" content="Tratamente decontate prin CAS, dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content=" Dentist Moieciu de Jos | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://carpathiandentist.ro" />
        <meta name="facebook:title" content=" Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        className={styles["carusel"]}
      >
        <Carousel.Item>
          <img
            src="https://i.imgur.com/PJcOHW6.jpg"
            alt="imagini"
            className={styles["imagine_carusel"]}
          />
              <img
            src="https://i.imgur.com/0ROO45N.jpeg"
            alt="imagini"
            className={styles["tratamente"]}
          />
          <Carousel.Caption>
            <h3>Tratamente decontate CAS</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item >
          <img
            src="https://i.imgur.com/zwJxGDw.png"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
         
          <Carousel.Caption>
            <h3>Servicii de calitate</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src="https://i.imgur.com/F3t8IAL.jpeg"
            alt="imagini"
            className={styles["imagini_carusel"]}
          />
          <Carousel.Caption>
            <h3>Aparatură modernă</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <img src="https://i.imgur.com/ntd06eg.jpeg" alt="cas"className={styles["cas"]}></img>
      <div className={styles["detalii"]}>
<div className={styles["detalii_div"]}>
  <h4 className={styles["titluCabinet"]}>
    CARPATHIAN DENTIST 
  </h4>
  <h4 className={styles["titluCabinet"]}>
   Cabinet stomatologic Moieciu de Jos
  </h4>
  <hr></hr>
  <p className={styles["titluCabinet2"]}><i>"Cabinetul de la poalele munților"</i></p>
  <p className={styles["texte"]}>
  Bun venit în cabinetul nostru dentar, locul unde zâmbetele sănătoase prind viață! Suntem dedicați sănătății și 
  frumuseții zâmbetului tău și 
  suntem încântați să te primim în spațiul nostru specializat în îngrijirea orală de cea mai înaltă calitate.</p>

<p className={styles["texte1"]}>Situat în pitorescul <strong>Moieciu de Jos</strong>, Carpathian Dentist îmbină tehnologia modernă cu grija și 
  profesionalismul, pentru a vă oferi tratamente dentare de cea mai înaltă calitate, într-un mediu cald și primitor.</p>
  <p className={styles["texte"]}>Domnul doctor <strong><a href="/despre-noi" className={styles["link"]}>Mușuroi Cosmin</a></strong>, specialist în protectică dentară, se ocupă cu restaurarea și înlocuirea dinților lipsă sau deteriorați pentru a restabili funcționalitatea și aspectul estetic al zâmbetului pacientului.</p>
<p className={styles["texte"]}><strong>Tratamente decontate prin CAS</strong>. La Carpathian Dentist, sănătatea orală este accesibilă tuturor. Beneficiați de tratamente decontate prin <strong>Casa de Asigurări de Sănătate</strong>, fără compromisuri în ceea ce privește calitatea.

Transformăm vizita la dentist într-o experiență plăcută. Contactați-ne pentru a descoperi cum vă putem ajuta să aveți un zâmbet sănătos si preturi accesibile!</p>
</div>
<div>
  <img src="https://i.imgur.com/OcBMmK6.png" alt="cabinet" className={styles["cabinet"]}/> 


</div>
      </div>
 
      <h1> Servicii</h1>
      <hr></hr>
      <div className={styles["servicii"]}>
        <Card style={{ width: "18rem" }} className={styles["cardbox"]}>
          <Card.Img
            variant="top"
            src="https://i.imgur.com/yCCxVp9.png"
            className={styles["card"]}
          />
          <Card.Body className={styles["carduri"]}>
            <Card.Title className={styles["titluri"]}>Consultație</Card.Title>
            <Card.Text className={styles["cardText"]}></Card.Text>
            <Link to="/contact" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} >
            <Button
              variant="primary"
              className={styles["butoane"]}
            
            >
              Solicită programare
            </Button>
              </Link>
          
           
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }} className={styles["cardbox"]}>
          <Card.Img
            variant="top"
            src="https://i.imgur.com/DxO1uQ2.png"
            className={styles["card"]}
          />
          <Card.Body className={styles["carduri"]}>
            <Card.Title className={styles["titluri"]}>Chirurgie</Card.Title>
            <Card.Text className={styles["cardText"]}>
              Extracția dentară este o intervenție chirurgicală de necessitate
              ce trebuie efectuată după epuizarea metodelor de tratament
              conservator.
            </Card.Text>
            <Link to="/servicii/chirurgie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} >
            <Button
              variant="primary"
              className={styles["butoane"]}
            
            >
              Vezi mai mult
            </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }} className={styles["cardbox"]}>
          <Card.Img
            variant="top"
            src="https://i.imgur.com/dnf9X7e.png"
            className={styles["card"]}
          />
          <Card.Body className={styles["carduri"]}>
            <Card.Title className={styles["titluri"]}>Protetică</Card.Title>
            <Card.Text className={styles["cardText"]}>
              Protetica dentară este ramura stomatologiei care se ocupă cu
              înlocuirea dinților lipsă sau reabilitarea orală complexă a
              arcadelor dentare.
            </Card.Text>
            <Link to="/servicii/protetica" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} >
            <Button
              variant="primary"
              className={styles["butoane"]}
            
            >
              Vezi mai mult
            </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }} className={styles["cardbox"]}>
          <Card.Img
            variant="top"
            src="https://i.imgur.com/ekmFXuI.png"
            className={styles["card"]}
          />
          <Card.Body className={styles["carduri"]}>
            <Card.Title className={styles["titluri"]}>Endodonție</Card.Title>
            <Card.Text className={styles["cardText"]}> 
              Endodonția este specialitatea stomatologică care se ocupă cu
              studiul și tratamentul afecțiunilor pulpei dentare (nervului) și
              al complicațiilor acestora.
            </Card.Text>
            <Link to="/servicii/endodontie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} >
            <Button
              variant="primary"
              className={styles["butoane"]}
           
            >
              Vezi mai mult
            </Button>
            </Link>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }} className={styles["cardbox"]}>
          <Card.Img
            variant="top"
            src="https://i.imgur.com/JUzOuaK.png"
            className={styles["card"]}
          />
          <Card.Body className={styles["carduri"]}>
            <Card.Title className={styles["titluri"]}>Pedodonția</Card.Title>
            <Card.Text className={styles["cardText"]}>
              Pedodonția, numită și stomatologie pediatrică, este specialitatea
              stomatologică care desfășoară măsuri de prevenție terapeutice
              pentru copii și adolescenți, cuprinzându-i și pe cei cu necesități
              speciale de tratament.
            </Card.Text>
            <Link to="/servicii/pedodontie" className={styles["linkuri"]} onClick={() => {
    window.scroll(0, 0);
  }} >
            <Button
              variant="primary"
              className={styles["butoane"]}
             
            >
              Vezi mai mult
            </Button>
            </Link>
          </Card.Body>
        </Card>
      </div>

    
      

      <h1>Contact</h1>
      <hr></hr>
      <div className={styles["contact"]}>
 

        <form ref={form} onSubmit={submitForm}  className={styles["form"]}>
          <label>Nume și prenume *</label>
          <input
            type="text"
            name="user_name"
            id="user_name"
            className={styles["input"]}
          />
          <label>Email *</label>
          <input
            required
            type="email"
            name="user_email"
            placeholder="nume@example.com"
            className={styles["input"]}
          />
          <label>Număr telefon *</label>
          <input
            required
            type="number"
            name="user_phone"
            placeholder="0745 000 000"
            className={styles["input"]}
          />
          <label>Motivul programării:</label>
          <textarea
            name="message"
            as="textarea"
            rows={3}
            className={styles["message"]}
          />
          {/* 6LfnqdYpAAAAABD50Vzwf1Rj8z2Eeu9l2_bjrJDT - cheia de la carpathian */}
          {/* 6LfBytYpAAAAAFik25GkHcz2rqVBnJ6jqtBhy5p4 - cheia de la local host */}
          {/* 6LdW89YpAAAAACYnNw3fLQTLsTRyMEWSqRDtssYD - cheia v2 */}
          {/* <input type="submit" value="Send" className="mb-3" /> */}
          {/* <div class="g-recaptcha" data-sitekey="6LfBytYpAAAAAFik25GkHcz2rqVBnJ6jqtBhy5p4"></div> */}
          <div className={styles["acord"]}>
          <input type="checkbox"name="checkbox" value="checkbox" className={styles["acord2"]} required/>
          <label for="checkbox"> Sunt de acord cu colectarea datelor personale furnizate în formularul de mai sus, pentru efectuarea programării. Pentru mai multe informații referitoare la prelucrarea datelor cu caracter personal, vă rugăm să consultați <a href="http://localhost:3000/politica-confidentialitate">politica GDPR.</a>
          </label>
          </div>
       
          <ReCAPTCHA sitekey="6LfnqdYpAAAAABD50Vzwf1Rj8z2Eeu9l2_bjrJDT" 
          ref={recaptcha}
          className={styles["captcha"]}
          />
    
        
         <Button  

            className={styles["submit2"]}
          >
       
          </Button>
          <input type="submit"   variant="outline-success"
            value="Trimite"  className={styles["submit"]}/>
      
        </form>
      
        <div className={styles["harta"]}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d174.79425982993772!2d25.33032978748338!3d45.49582139227349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b337f8657020fd%3A0xc8223ddf64fc0547!2sCarpathian%20Dentist!5e0!3m2!1sro!2sro!4v1714383316629!5m2!1sro!2sro"
            width="600"
            height="500"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          
        </div>
      </div>
      <br></br>

      <MDBModal open={basicModal} setopen={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Info</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Programarea ta a fost inregistrata, vei primi confirmarea pe
              email.{" "}
            </MDBModalBody>

            <MDBModalFooter>
              <a href="/home">
                {" "}
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Inchide
                </MDBBtn>
              </a>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <ElfsightWidget widgetId="7175d707-99bc-448d-8e6b-8feb11f8f3b0" className={styles["recenzii"]}/>
    </>
  );
}
