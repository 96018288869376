
import { Helmet } from "react-helmet";
import styles from "./Preturi.module.css";

export function Preturi() {

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Preturi | Carpathian Dentist | Cabinet dentar </title>
                <link rel="canonical" href="/preturi" />
                <meta name="description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate. Suna la 0752859298 si programeaza-te!" />
                <meta name="keywords" content="dentist, cabinet dentar moieciu, dentist moieciu de jos, clinica dentara,Tratamente decontate prin CAS" />
        <meta name="author" content="Carpathian Dentist" />
        <meta property="og:title" content="Preturi | Carpathian Dentist | Cabinet dentar" />
        <meta property="og:description" content="Tratamente decontate prin CAS.Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta property="og:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="facebook:title" content="Preturi | Carpathian Dentist | Cabinet dentar" />
        <meta name="facebook:description" content="Tratamente decontate prin CAS. Servicii dentare de calitate, aparatura moderna si profesionalism. Dl Dr. Musuroi Cosmin, specialist in protetica dentara, te astepta cu servicii de inalta calitate." />
        <meta name="facebook:image" content="https://i.imgur.com/gKhjL20.png" />
        <meta name="facebook:card" content="https://i.imgur.com/Rcop7CM.png" />





            </Helmet>
    <h1><strong>Preturi</strong></h1>
      <img
        src="https://i.imgur.com/UMuSc9E.png"
        alt="preturi"
        className={styles["figure"]}
      ></img>
<div className={styles["tabele"]}>
      <table striped bordered hover className={styles["tabel"]}>
        <thead>
          <tr>
            <th>PROFILAXIE</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Consultatie</td>
            <td></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Detartraj, periaj, airflow</td>
            <td>300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Detartraj, periaj, airflow, instructaj periaj, parodontometrie</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Periaj sau airflow</td>
            <td>100 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Fluorizare 1 dinte</td>
            <td>20 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Fluorizare 1 arcadă</td>
            <td>100 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Fluorizări în gutiere</td>
            <td>120 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Sigilare 1 dinte</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Albire profesională în cabinet Philips Zoom</td>
            <td>1300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Gutiere albire/de bruxism/de contenție</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Fatețe din compozit</td>
            <td>500 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Albire internă</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Albire dentara laser completa</td>
            <td>1000 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Albire laser /dinte</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Full mouth desinfection - tratamentul bolii parodontale</td>
            <td>2000 RON</td>
          </tr>
        </tbody>
      </table>
      <table striped bordered hover className={styles["tabel"]}>
        <thead>
          <tr>
            <th>ODONTOTERAPIE RESTAURATORIE</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Obturație compozit 1 suprafață</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație compozit 2 suprafețe</td>
            <td>300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație compozit 3 suprafețe</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație fizionomică pe dinți frontali</td>
            <td>300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Reconstructie compozit (build up)</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație de colet</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Tratamentul hiperesteziei</td>
            <td>100 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație glassionomeri</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturatie fisuri</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Pivot parapulpar</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Pivot metalic</td>
            <td>200 RON</td>
          </tr>
        </tbody>
      </table>
      <table striped bordered hover className={styles["tabel"]}>
        <thead>
          <tr>
            <th>ENDODONȚIE</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Pivot de fibră de sticlă</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extirpare vitală + obturație endo 1 canal </td>
            <td>550 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extirpare vitală + obturație endo 3 canale</td>
            <td>750 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Retratament endodontic 1 canal</td>
            <td>650 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Retratament endodontic 2 canale</td>
            <td>750 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Retratament endodontic 3 canale</td>
            <td>850 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Dezobturare canal</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Tratament hidroxid de calciu</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Tratament gangrenă</td>
            <td>300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Îndepărtare pivot</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Îndepărtare obiect separat in canal</td>
            <td>350 RON</td>
          </tr>
        </tbody>
      </table>
      <table striped bordered hover className={styles["tabel"]}>
        <thead>
          <tr>
            <th>PROTETICĂ DENTARĂ</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Proteză acrilică simplă</td>
            <td>1800 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Proteză acrilică cu dinți ceramici </td>
            <td>2500 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Proteză scheletată cu sisteme speciale</td>
            <td>3000 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Reparație proteză</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Adăugare dinte/croșet în protezăe</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Rebazare protezăe</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>DCR metalic</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>DCR zirconiu</td>
            <td>400 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Proteză Kemeny</td>
            <td>300 RON/dinte</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Reparație coroană compozit</td>
            <td>50 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Reparație coroană ceramică în cabinet</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Îndepărtare coroană</td>
            <td>50 RON/element</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană provizorie în cabinet</td>
            <td>75 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană metalo-ceramică</td>
            <td>650 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană metalică</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană PMMA</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană zirconiu monolitic lateral</td>
            <td>950 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană zirconiu + ceramica lateral</td>
            <td>1100 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană zirconiu frontală</td>
            <td>1100 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană zirconiu + ceramica emax frontală</td>
            <td>1300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Coroană ceramică emax integrală</td>
            <td>1300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Inlay/onlay compozit</td>
            <td>700 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Inlay/onlay ceramică</td>
            <td>1000 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Fateta ceramică emax</td>
            <td>1300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Cimentare provizorie</td>
            <td></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Cimentare definitivă</td>
            <td>50 RON/element</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Modele de studiu</td>
            <td>100 RON/arcada</td>
          </tr>
        </tbody>
      </table>
      <table striped bordered hover className={styles["tabel"]}>
        <thead>
          <tr>
            <th>CHIRURGIE DENTO-ALVEOLARĂ</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Anestezie de contact</td>
            <td></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Anestezie prin inflitrație </td>
            <td></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție dinte monoradicular</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție dinte pluriradicular</td>
            <td>300 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție dinte parodontotic</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție dinte temporar</td>
            <td>100-200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție rest radicular</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție molar „de minte” erupt complet</td>
            <td>400 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție molar “de minte” incomplet erupt</td>
            <td>500 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Extracție molar “de minte” inclus</td>
            <td>600 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Rezecție apicală frontali</td>
            <td>350 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Rezecție apicală dinți laterali</td>
            <td>450 RON/element</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Premolarizare</td>
            <td>250 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Decapusonare</td>
            <td>150 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Sutură alveolă</td>
            <td></td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Regularizare creastă</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Descoperire dinți inclusi</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        
      </table>
      <table striped bordered hover className={styles["tabel2"]}>
        <thead>
          <tr>
            <th>PARODONTOLOGIE</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Gingivectomie</td>
            <td>150 RON/ dinte</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Gingivoplastie </td>
            <td>150 RON/ dinte</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Chiuretaj pungă parodontala</td>
            <td>75 RON/ dinte</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Imobiliazare cu fibră</td>
            <td>50 RON/ dinte</td>
          </tr>
        </tbody>
    
  
        <thead>
          <tr>
            <th>PEDODONȚIE</th>
            <th>TARIF</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Obturație glassionomer dinte temporar</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație  compozit dinte temporar </td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Sigilare molar</td>
            <td>200 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Obturație provizorie dinte temporar</td>
            <td>50 RON</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Drenaj dinte temporar</td>
            <td>200 RON</td>
          </tr>
        </tbody>
    
        
      </table>
      </div>
    </>
  );
}
